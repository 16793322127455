<template>
  <v-main>
    <v-container class="px-0 pb-0 pa-sm-4">

      <div class="d-flex justify-center">
        <a href="/static-downloads/Kundeninformation.pdf" download class="text-decoration-none" style2="width: 100%">
          <v-btn color="primary" class="mb-3 mb-sm-4" outlined2 block>Als PDF herunterladen</v-btn>
        </a>
      </div>

      <v-card class="px-sm-4 py-sm-2 px-md-6 py-md-4">
        <v-card-title>
          Kundeninformation
        </v-card-title>
        <v-card-text class="black--text text-body-1">

          <p><span data-contrast="auto">Herzlich Willkommen auf meiner Seite und im Netzwerk des Financial Guidance.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Mein Name ist Roy Spitzner. Ich bin 32 Jahre alt und bin seit 8 Jahren selbstst&auml;ndig als Referent und Berater f&uuml;r Akademiker im Bereich pers&ouml;nliche Entwicklung und Finanzen t&auml;tig. Dabei habe ich mich verpflichtet meine Dienstleistung ausschlie&szlig;lich kundenzentriert durchzuf&uuml;hren. Daf&uuml;r verwende ich ein Netzwerk, welches sich aus dem Karriereinstitut, der WirPersonalberater GmbH, der Unternehmerberatung JRT GmbH, der mitNORM Gewerbemakler GmbH und der mitNORM GmbH zusammensetzt.&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Auf Grund meiner T&auml;tigkeit findest du in dieser Kundeninformation den Nachweis, dass ich f&uuml;r dich rechtlich abgesichert die bestm&ouml;gliche Beratung durchf&uuml;hren kann.&nbsp;&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die Basis f&uuml;r unsere Zusammenarbeit bildet unser Leitbild zusammen mit unseren Werten:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Wir navigieren - du setzt um!</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto"> </span><span data-ccp-props="{&quot;335559685&quot;:540}">&nbsp;</span></p>
          <p><span data-contrast="auto">Im </span><strong><span data-contrast="auto">Financial Guidance</span></strong><span data-contrast="auto"> steht dir unser Team aus engagierten und freundlichen Menschen zur Verf&uuml;gung. Wir denken, handeln und kommunizieren immer positiv-l&ouml;sungsorientiert und nach dem Prinzip der Eigenverantwortung. Wir arbeiten konsequent im Rahmen unserer 8 Kernwerte und stellen sicher, dass jeder mit dem wir Kontakt haben, erfolgreicher wird.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Wir unterst&uuml;tzen bedingungslos.&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto"> </span><span data-ccp-props="{&quot;335559685&quot;:540}">&nbsp;</span></p>
          <p><span data-contrast="auto">Wir f&ouml;rdern und fordern deine Weiterentwicklung im Mindset, in deiner Kommunikation und deinen F&uuml;hrungskompetenzen. Dabei schaffst du dir eine berufliche Wohlf&uuml;hlatmosph&auml;re mit sozialer und wirtschaftlicher Besserstellung. Dabei bleibst du Selbstbestimmt. F&uuml;r die Erreichung deiner wirtschaftlichen Ziele erh&auml;ltst du den schnellsten und k&uuml;rzesten Weg, ohne Inkaufnahme unn&ouml;tiger Risiken. Die Sicherheit gew&auml;hrleisten wir durch h&ouml;chste Qualit&auml;tsanforderung nach Norm- und Verbraucherschutzkriterien.&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto"> </span><span data-ccp-props="{&quot;335559685&quot;:540}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die ganzheitliche Betrachtung der Lebenssituation sch&auml;tzen unsere Kunden besonders und nutzen die praxiserprobten Handlungsempfehlungen.&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Unsere Kunden w&auml;hlen wir nicht aufgrund der aktuellen Situation aus, sondern vielmehr aufgrund ihrer Einstellung, die eigenen Ziele zu verfolgen und die Bereitschaft Hilfe anzunehmen.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto"> </span><span data-ccp-props="{&quot;335559685&quot;:540}">&nbsp;</span></p>
          <p><span data-contrast="auto">Unsere Vision:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto"> </span><span data-ccp-props="{&quot;335559685&quot;:540}">&nbsp;</span></p>
          <p><span data-contrast="auto">Dein pers&ouml;nlicher Guide f&uuml;r deine pers&ouml;nliche, berufliche und finanzielle Zufriedenheit.&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto"> </span><span data-ccp-props="{&quot;335559685&quot;:540}">&nbsp;</span></p>
          <p><span data-contrast="auto">Fixstern:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Wir werden 10.000 Menschen erfolgreicher machen.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto"> </span><span data-ccp-props="{&quot;335559685&quot;:540}">&nbsp;</span></p>
          <p><span data-contrast="auto">Unsere Werte</span><span data-ccp-props="{}">&nbsp;</span></p>
          <ol>
            <li data-leveltext="%1." data-font="Calibri,Times New Roman" data-listid="9" aria-setsize="-1" data-aria-posinset="1" data-aria-level="1"><span data-contrast="auto">Wir entwickeln uns stets pers&ouml;nlich weiter (wir fordern &amp; f&ouml;rdern)</span><span data-ccp-props="{&quot;335559685&quot;:1800}">&nbsp;</span></li>

            <li data-leveltext="%1." data-font="Calibri,Times New Roman" data-listid="9" aria-setsize="-1" data-aria-posinset="2" data-aria-level="1"><span data-contrast="auto">Wir sind dankbar</span><span data-ccp-props="{&quot;335559685&quot;:1800}">&nbsp;</span></li>

            <li data-leveltext="%1." data-font="Calibri,Times New Roman" data-listid="9" aria-setsize="-1" data-aria-posinset="3" data-aria-level="1"><span data-contrast="auto">Wir unterst&uuml;tzen uns bedingungslos</span><span data-ccp-props="{&quot;335559685&quot;:1800}">&nbsp;</span></li>

            <li data-leveltext="%1." data-font="Calibri,Times New Roman" data-listid="9" aria-setsize="-1" data-aria-posinset="4" data-aria-level="1"><span data-contrast="auto">Wir sind positiv l&ouml;sungsorientiert</span><span data-ccp-props="{&quot;335559685&quot;:1800}">&nbsp;</span></li>

            <li data-leveltext="%1." data-font="Calibri,Times New Roman" data-listid="9" aria-setsize="-1" data-aria-posinset="5" data-aria-level="1"><span data-contrast="auto">Wir haben Freude und Spa&szlig; in der Zusammenarbeit</span><span data-ccp-props="{&quot;335559685&quot;:1800}">&nbsp;</span></li>

            <li data-leveltext="%1." data-font="Calibri,Times New Roman" data-listid="9" aria-setsize="-1" data-aria-posinset="6" data-aria-level="1"><span data-contrast="auto">Wir stehen f&uuml;r Eigenverantwortung</span><span data-ccp-props="{&quot;335559685&quot;:1800}">&nbsp;</span></li>

            <li data-leveltext="%1." data-font="Calibri,Times New Roman" data-listid="9" aria-setsize="-1" data-aria-posinset="7" data-aria-level="1"><span data-contrast="auto">Wir treffen mutige Entscheidungen und Fehler sind erlaubt</span><span data-ccp-props="{&quot;335559685&quot;:1800}">&nbsp;</span></li>

            <li data-leveltext="%1." data-font="Calibri,Times New Roman" data-listid="9" aria-setsize="-1" data-aria-posinset="8" data-aria-level="1"><span data-contrast="auto">Wir geben nie auf und k&auml;mpfen beharrlich f&uuml;r unsere Ziele</span><span data-ccp-props="{&quot;335559685&quot;:1800}">&nbsp;</span></li>
          </ol>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Ich freue mich auf die Zusammenarbeit mit dir!</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Als Hinweis zur Zusammenarbeit im Finanzbereich ist es meine Pflicht dir nachzuweisen, dass ich fachlich und qualitativ die h&ouml;chsten Standards erf&uuml;lle und deshalb findest du auf den folgenden Seite alle notwendigen Informationen zu meinem Kooperationspartner der mitNORM GmbH.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">mitNORM GmbH</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Rendsburger Stra&szlig;e 14, 30659 Hannover Telefon: 0511 5636</span><span data-contrast="auto">‑</span><span data-contrast="auto">5720 Telefax: 0511 5636</span><span data-contrast="auto">‑</span><span data-contrast="auto">5454</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Gesch&auml;ftsf&uuml;hrer: Michael Rublack E</span><span data-contrast="auto">‑</span><span data-contrast="auto">Mail: info@mitNORM.com Internet: </span><a href="http://www.mitNORM.de"><span data-contrast="none">www.mitNORM.de</span></a><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">mitNORM GmbH - Gepr&uuml;fte Finanzberatung</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die mitNORM GmbH ist ein Allfinanzberatungs</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Vermittlungsunternehmen.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Auf Basis des Regelwerks der DIN 77230 analysiert die mitNORM GmbH die individuelle Finanz</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Risikosituation des Mandanten, identifiziert seinen pers&ouml;nlichen Bedarf und erarbeitet gemeinsam mit dem Mandanten einen Finanzfahrplan f&uuml;r die Bereiche Risiko</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Verm&ouml;genssicherung, Vorsorge und Finanzplanung. Die auf die Bed&uuml;rfnisse des Mandanten zugeschnittene</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Produktauswahl erfolgt unter Ber&uuml;cksichtigung der Verbraucherschutzkriterien von Finanztest.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die Analyse nach DIN 77230 gew&auml;hrleistet stets eine objektive und auf die individuelle Mandantensituation zugeschnittene Analyse und den daraus folgenden Finanzfahrplan. Es werden ausschlie&szlig;lich Kapitalanlagen und Investmentfonds angeboten, welche von einem unabh&auml;ngigen Fachbeirat ausgew&auml;hlt oder von Finanztest empfohlen wurden.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Erlaubnis und Registrierung als Versicherungsvermittler</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Im Gesch&auml;ftsbereich Versicherungen &uuml;bt die mitNORM GmbH die T&auml;tigkeit als Versicherungsmakler gem&auml;&szlig; &sect; 59 Abs. 3 VVG aus; im &Uuml;brigen ist die mitNORM GmbH als Handelsmakler gem&auml;&szlig; &sect; 93 HGB t&auml;tig. Die mitNORM GmbH ist als Versicherungsmakler gem&auml;&szlig; &sect; 34 d Abs. 1 GewO bei der zust&auml;ndigen Beh&ouml;rde gemeldet</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">und unter der Nummer D-BF4L-VXVS6-65 im Versicherungsvermittlerregister eingetragen. Das Register wird gef&uuml;hrt von dem Deutschen Industrie</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Handelskammertag (DIHK) e.V., Breite Stra&szlig;e 29, 10178 Berlin, Tel. 030 20308</span><span data-contrast="auto">‑</span><span data-contrast="auto">0 und kann eingesehen werden unter: </span><a href="http://www.vermittlerregister.info"><span data-contrast="none">www.vermittlerregister.info</span></a><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Erlaubnis und Registrierung als Finanzanlagen- und Immobilienfinanzierungsvermittler</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die Registrierungsnummer gem&auml;&szlig; &sect;&sect; 34 f Abs. 1,5 GewO, &sect; 11 a Abs. 1 GewO bei der zust&auml;ndigen Beh&ouml;rde lautet: D</span><span data-contrast="auto">‑</span><span data-contrast="auto">F</span><span data-contrast="auto">‑</span><span data-contrast="auto">133</span><span data-contrast="auto">‑</span><span data-contrast="auto">GLRS</span><span data-contrast="auto">‑</span><span data-contrast="auto">54.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die Erlaubnis umfasst die Vermittlung von und die Beratung zu Finanzanlagen nach:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul>
            <li><span data-contrast="auto"> &sect; 34 f Abs. 1 Nr. 1 GewO (Investmentfonds, die im Geltungsbereich des InvG &ouml;ffentlich vertrieben werden d&uuml;rfen)</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die Erlaubnis gem&auml;&szlig; &sect; 34 f Abs. 1 Nr. 1, 2 und &sect; 34 i Abs.1 S.1. GewO ist von der zust&auml;ndigen Beh&ouml;rde, IHK Hannover, Schiffgraben 49, 301575 Hannover, Tel. 0511 3107</span><span data-contrast="auto">‑</span><span data-contrast="auto">0</span><span data-contrast="auto">‑</span><span data-contrast="auto">, www.hannover.ihk.de, erteilt worden.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die Registrierungsnummer f&uuml;r die Vermittlung von Immobiliendarlehen gem&auml;&szlig; &sect; 34 i Abs.1 S.1 GewO lautet D-W-133-N2LF-35.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Das Register wird gef&uuml;hrt von dem Deutschen Industrie</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Handelskammertag (DIHK) e.V., Breite Stra&szlig;e 29, 10178 Berlin, Tel. 030 20308</span><span data-contrast="auto">‑</span><span data-contrast="auto">0 und kann eingesehen werden unter: </span><a href="http://www.vermittlerregister.info"><span data-contrast="none">www.vermittlerregister.info</span></a><span data-contrast="auto">.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><strong><span data-contrast="auto">Beteiligungen</span></strong><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die mitNORM GmbH und der mitNORM Berater besitzen keine direkten oder indirekten Beteiligungen von &uuml;ber 10% an den Stimmrechten oder am Kapital eines Versicherungsunternehmens. Dar&uuml;ber hinaus besitzt kein Versicherungsunternehmen</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">oder Mutterunternehmen eines Versicherungsunternehmens eine direkte oder indirekte Beteiligung an den Stimmrechten oder am Kapital der mitNORM GmbH oder an der Firma des mitNORM Beraters.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><strong><span data-contrast="auto">mitNORM Berater, Erlaubnis und Registrierung als Versicherungsvermittler</span></strong><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die mitNORM GmbH nimmt ihre T&auml;tigkeit durch den mitNORM Berater wahr. Auch er ist als Versicherungsmakler gem&auml;&szlig; &sect; 59 Abs. 3 VVG sowie als Finanzanlagenvermittler gem&auml;&szlig; der FinVermV t&auml;tig.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Herr Roy Spitzner (Nr. 96360), Innere Schneeberger Stra&szlig;e 21, 08056 Zwickau</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Telefonnummer: 017662898483 Email: </span><a href="mailto:r.spitzner@mitnorm.com"><span data-contrast="none">r.spitzner@mitnorm.com</span></a><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Der mitNORM Berater ist als Versicherungsmakler gem&auml;&szlig; &sect; 34 d Abs. 1 GewO bei der zust&auml;ndigen Beh&ouml;rde:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">IHK Chemnitz</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Stra&szlig;e der Nationen 25, 09111 Chemnitz</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Telefonnummer: 0371-69001300</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Homepage: </span><a href="http://www.chemnitz.ihk.de"><span data-contrast="none">www.chemnitz.ihk.de</span></a><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">gemeldet und unter der Nummer D-NEDS-5HSTP-43 (www.vermittlerregister.info), Kontaktdaten siehe oben.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">im Vermittlerregister eingetragen.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Der mitNORM Berater ist als Finanzanlagenvermittler gem&auml;&szlig; &sect;&sect; 34 f Abs. 1, 5 GewO, &sect; 11 aAbs. 1 GewO bei der zust&auml;ndigen Beh&ouml;rden gemeldet und unter der Nummer D-F-144-A239-84 im Finanzanlagenvermittlerregister eingetragen.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Diese Erlaubnis umfasst die Vermittlung von und die Beratung zu Finanzanlagen nach</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul>
            <li><span data-contrast="auto"> &sect; 34 f Abs. 1 Nr. 1 GewO (Investmentfonds, die im Geltungsbereich des InvG &ouml;ffentlichvertrieben werden d&uuml;rfen)</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Das Register wird gef&uuml;hrt von dem Deutscher Industrie</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Handelskammertag (DIHK) e.V., Breite Stra&szlig;e 29, 10178 Berlin, Tel. 030 20308</span><span data-contrast="auto">‑</span><span data-contrast="auto">0 und kann eingesehen werden unter: www.vermittlerregister.info</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die Erlaubnis gem&auml;&szlig; &sect; 34 f Abs. 1 Nr. 1 GewO ist erteilt worden seitens der zust&auml;ndigen Beh&ouml;rde.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">IHK Chemnitz</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Stra&szlig;e der Nationen 25, 09111 Chemnitz</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Telefonnummer: 0371-69001300</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Homepage: </span><a href="http://www.chemnitz.ihk2.de"><span data-contrast="none">www.chemnitz.ihk2.de</span></a><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><strong><span data-contrast="auto">Zahlungsmittel</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die mitNORM GmbH und der mitNORM Berater sind nicht zur Annahme von Zahlungsmitteln f&uuml;r Dritte (z.B. Bargeld, &Uuml;berweisungen, Schecks, Wertpapiere, usw.) bevollm&auml;chtigt.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><strong><span data-contrast="auto">Beratungsqualit&auml;t- Schlichtungsstelle gem&auml;&szlig; &sect; 214 VVG</span></strong><span data-contrast="auto">&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Eine hohe Beratungs</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Servicequalit&auml;t sind f&uuml;r die mitNORM GmbH selbstverst&auml;ndlich. Sind Sie als Mandant dennoch mit der Beratung oder Vermittlung von Versicherungen unzufrieden oder ein Problem mit einem Versicherer, steht Ihnen unser Mandantenqualit&auml;tsmanagement jederzeit zur Verf&uuml;gung.&nbsp;</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">mitNORM GmbH Qualit&auml;tsmanagement Rendsburger Stra&szlig;e 14 30659 Hannover</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Daneben kann zur au&szlig;ergerichtlichen Beilegung von Streitigkeiten zwischen Versicherungsnehmer und Vermittler im Zusammenhang mit der Vermittlung von Versicheurngsvertr&auml;gen die folgende unabh&auml;ngigige Schlichtungsstelle angerufen werde:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Schlichtungsstelle f&uuml;r gewerbliche Versicherungs</span><span data-contrast="auto">‑</span><span data-contrast="auto">, Anlage</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Kreditvermittlung Glockengie&szlig;erwall 2, 20095 Hamburg</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><strong><span data-contrast="auto">Information zur Datenverarbeitung&nbsp;</span></strong><span data-ccp-props="{}">&nbsp;</span></p>
          <ol>
            <li><span data-contrast="auto"> Allgemeine Informationen und verantwortlichen Stelle Die nachfolgenden Informationen dienen der Erf&uuml;llung der Informationspflicht bei Erhebung von personenbezogenen Daten bei der betroffenen Person gem&auml;&szlig; Art.13 DSGVO.</span></li>
          </ol>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Diese gelten f&uuml;r die Verarbeitung personenbezogener Daten, die aufgrund des zwischen uns bestehenden Vertragsverh&auml;ltnisses stattfindet. Personenbezogene Daten sind dabei alle Informationen, die sich auf eine identifizierte oder identifizierbare nat&uuml;rliche Person beziehen, wie etwa Name, Anschrift, Telefonnummer, E</span><span data-contrast="auto">‑</span><span data-contrast="auto">Mail</span><span data-contrast="auto">‑</span><span data-contrast="auto">Adresse, Beruf, Bankverbindung etc. Unter der Verarbeitung von Daten versteht man insbesondere deren Erhebung, Speicherung, Verwendung und &Uuml;bermittlung. Verantwortliche Stelle gem&auml;&szlig; Art. 13 Abs. 1 Lit a) DSGVO sind wir, als Ihre Vertragspartner im Rahmen des zwischen uns bestehenden Vertragsverh&auml;ltnisses:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">mitNORM GmbH Rendsburger Stra&szlig;e 14, 30659 Hannover Telefon: 0511 5636</span><span data-contrast="auto">‑</span><span data-contrast="auto">5720 Gesch&auml;ftsf&uuml;hrer: Michael Rublack Sitz der Gesellschaft: Hannover E</span><span data-contrast="auto">‑</span><span data-contrast="auto">Mail: info@mitNORM.com Internet: www.mitNORM.de und der von Ihnen ausgew&auml;hlte mitNORM Berater.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ol>
            <li><span data-contrast="auto"> Informationen zur Verarbeitung Ihrer personenbezogenen Daten</span></li>
          </ol>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">1) Zweck der Verarbeitung personenbezogener Daten sowie deren Rechtsgrundlage, Art. 13 Abs. 1 lit.c) DSGVO</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Zweck der Verarbeitung Ihrer personenbezogenen Daten ist die Erf&uuml;llung des zwischen uns geschlossenen Maklervertrags sowie die Erf&uuml;llung unserer gesetzlichen Beratungs</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Vermittlungspflichten. Hierzu ist die Verarbeitung personenbezogener Daten notwendig. In einigen F&auml;llen, wie etwa im Rahmen der Vermittlung von Berufsunf&auml;higkeitsversicherungen, ist dazu auch die Verarbeitung besonderer Kategorien personenbezogener Daten erforderlich. Besondere Kategorien personenbezogener Daten sind etwa Gesundheitsdaten, Daten zur Religions</span><span data-contrast="auto">‑</span><span data-contrast="auto">, Gewerkschafts</span><span data-contrast="auto">‑</span><span data-contrast="auto">, Parteizugeh&ouml;rigkeit, rassischen und ethnischen Herkunft, zum Sexualleben und zur sexuellen Orientierung sowie genetische und biometrische Daten. Schlie&szlig;lich sind auch der Abschluss und die nachfolgende Durchf&uuml;hrung eines jeden Versicherungsvertrages ohne die Verarbeitung Ihrer personenbezogenen Daten nicht m&ouml;glich.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Wir verarbeiten Ihre Daten ausschlie&szlig;lich zum vorgenannten Zweck und unserer hieraus folgenden Pflichten.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf der Rechtsgrundlage der Vertragsdurchf&uuml;hrung gem&auml;&szlig; Art 6 Abs. 1 S. 1 lit. b) DSGVO, der Erf&uuml;llung gesetzlicher Verpflichtungen gem&auml;&szlig; Art 6 Abs. 1 S. 1 lit. c DSGVO und der nachfolgenden Einwilligung</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. a) DSGVO. Hinsichtlich der Verarbeitung besonderer Kategorien personenbezogener Daten erfolgt die Verarbeitung ausschlie&szlig;lich aufgrund der nachfolgenden Einwilligung gem&auml;&szlig; Art. 9 Abs. 2 lit. a) DSGVO. Weiterhin kann die Verarbeitung Ihrer personenbezogenen Daten auf einem berechtigten Interesse gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. f) DSGVO beruhen, so etwa im Falle einer Rechtsnachfolge. Schlie&szlig;lich k&ouml;nnen Ihre Daten teilweise automatisiert verarbeitet werden mit dem Ziel, bestimmte pers&ouml;nliche Aspekte zu bewerten (Profiling). So wird Profiling etwa in folgenden F&auml;llen eingesetzt:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Aufgrund gesetzlicher und regulatorischer Vorgaben sind wir in verschiedenen Produktbereichen, insbesondere bei Investment</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Versicherungsanlageprodukten, zur Vornahme eines Zielmarktabgleichs verpflichtet, um zu pr&uuml;fen, ob das Produkt f&uuml;r Sie geeignet bzw. angemessen ist. Dabei werden auch Datenauswertungen vorgenommen. In die Bewertung flie&szlig;en beispielsweise Ihre Kenntnisse und Erfahrungen mit Anlageprodukten, Ihre finanziellen Verh&auml;ltnisse, Ihre Verlusttragf&auml;higkeit, Ihre Anlageziele und Ihre Risikotoleranz ein. Diese werden mit den entsprechenden Produktvorgaben automatisiert abgeglichen. Diese Ma&szlig;nahmen unterst&uuml;tzen uns bei der Erbringung unserer Anlageberatungs</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Vermittlungsleistungen und dienen zugleich auch Ihrem Schutz. Die Erforderlichkeit und der Umfang der Datenverarbeitung richten sich nach den von Ihnen gew&uuml;nschten Beratungs</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und Vermittlungsleistungen. Ihre Einwilligung f&uuml;r die Verarbeitung Ihrer personenbezogenen Daten durch von uns eingesetzten Dienstleister ist erforderlich, wenn diese nicht als Auftragsverarbeiter i.S.d. Art. 28 DSGVO t&auml;tig sind.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Ihre Einwilligung dient au&szlig;erdem auch dazu, Ihre personenbezogenen Daten an dritte Stellen zu &uuml;bermitteln, wie z. B. Maklerpools, Betreiber von Vergleichsportalen etc., mit denen wir im Rahmen unserer Maklert&auml;tigkeit regelm&auml;&szlig;ig zusammenarbeiten.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">2) Daten&uuml;bermittlung und Empf&auml;nger bzw. Kategorien von Empf&auml;ngern der personenbezogenen Daten, Art. 13 Abs. 1 lit. e) DSGVO</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Im Rahmen von Erf&uuml;llung des zwischen uns bestehenden Vertragsverh&auml;ltnisses ist es zum Teil erforderlich, dass Ihre personenbezogenen Daten von uns an dritte Stellen &uuml;bermittelt bzw. die Daten von diesen dritten Stellen empfangen werden. Dies ist insbesondere der Fall bei Deckungsanfragen, Abschl&uuml;ssen von Versicherungsvertr&auml;gen, Verwaltung von Vertr&auml;gen und der Abwicklung von Schadensf&auml;llen usw. Bei den Empf&auml;ngern bzw. Kategorien von Empf&auml;ngern Ihrer Daten handelt es sich um:</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Maklerpools, Versicherungsunternehmen, R&uuml;ckversicherungsunternehmen, Dienstleistungsunternehmen, Versicherungsmakler, Sozialversicherungstr&auml;ger, Kreditinstitute und Kapitalanlagegesellschaften, Bausparkassen, Finanzdienstleistungsinstitute und Wertpapierhandelsgesellschaften, Rechtsanw&auml;lte, Steuerberater, Wirtschaftspr&uuml;fer, Versicherungsombudsm&auml;nner, Bundesanstalt f&uuml;r Finanzdienstleistungsaufsicht (BaFin), Assekuradeur und Rechtsnachfolger. Eine Liste aller Empf&auml;nger und Kategorien finden Sie in jeweils aktueller Fassung auf unserer Webseite unter der URL https://www. mitnorm.de/kontakt. Gerne senden wir Ihnen die jeweils aktuelle Liste der Empf&auml;nger auf Anfrage auch postalisch zu. Ihre personenbezogenen Daten werden von uns lediglich in dem Ma&szlig; &uuml;bermittelt, wie es zu dem oben genannten Zweck der Verarbeitung erforderlich ist.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">3) Dauer der Speicherung Ihrer personenbezogenen Daten, Art. 13 Abs. 2 lit. a) DSGVO</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Ihre Daten werden bei uns f&uuml;r die Dauer des zwischen uns bestehenden Vertragsverh&auml;ltnisses gespeichert. Nach Beendigung des Vertragsverh&auml;ltnisses ist die weitere Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt. Eine weitere Verwendung, insbesondere Speicherung der Daten, findet ab diesem Zeitpunkt nur noch zu den in Art. 17 Abs. 3 und Art. 18 Abs. 2 DSGVO genannten Zwecken statt. Die hier genannten Zwecke beinhalten insbesondere die Erf&uuml;llung einer rechtlichen Verpflichtung durch uns, wie etwa die Einhaltung gesetzlicher Aufbewahrungspflichten sowie die Geltendmachung, Aus&uuml;bung und Verteidigung von Rechtsanspr&uuml;chen, wie etwa durch Nachweis einer ordnungsgem&auml;&szlig;en Beratung. Gesetzliche Aufbewahrungsfristen auf Grundlage handels</span><span data-contrast="auto">‑</span><span data-contrast="auto"> und steuerrechtlicher Bestimmungen betragen bis zu 10 Jahre. Verj&auml;hrungsfristen f&uuml;r Anspr&uuml;che betragen bis zu 30 Jahre. Beratungsprotokolle und sonstige Unterlagen zum Nachweis ordnungsgem&auml;&szlig;er Beratung und zur Verteidigung gegen Anspr&uuml;che bewahren wir daher bis zum Ablauf der jeweiligen Verj&auml;hrungsfrist auf. Soweit Ihre personenbezogenen Daten zu den in dieser Ziffer genannten Zwecken nicht mehr ben&ouml;tigt werden, insbesondere also auch gesetzliche Aufbewahrungsfristen abgelaufen sind, werden von uns vollst&auml;ndig und irreversibel gel&ouml;scht.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">4) Ihre Rechte als Betroffener der Datenverarbeitung</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul>
            <li><span data-contrast="auto"> Auskunft, Art. 13 Abs. 2 lit. b), Art. 15 DSGVO Sie haben das Recht von uns Auskunft &uuml;ber die bei uns &uuml;ber Sie gespeicherten personenbezogenen Daten zu verlangen. Diese Auskunft erteilen wir Ihnen nach Ihrer Anfrage gerne. Weiterhin teilen wir Ihnen auf Anfrage gerne mit, an welche dritten Stellen Ihre personenbezogenen Daten w&auml;hrend der Erf&uuml;llung des zwischen uns bestehenden Vertragsverh&auml;ltnisses &uuml;bermittelt wurden.</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul>
            <li><span data-contrast="auto"> Berichtigung, Art. 13 Abs. 2 lit. b), Art. 16 DSGVO Sie haben das Recht von uns zu verlangen Ihre personenbezogenen Daten zu berichtigen oder zu vervollst&auml;ndigen. Geben Sie uns keinen entsprechenden Hinweis, so erfolgt dies jedenfalls unverz&uuml;glich dann, wenn uns bekannt wird, dass die bei uns gespeicherten Daten fehlerhaft oder unvollst&auml;ndig sind.</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul start="3">
            <li><span data-contrast="auto"> L&ouml;schung, Art. 13 Abs. 2 lit. b), Art. 17 DSGVO Sie haben das Recht von uns L&ouml;schung der bei uns gespeicherten personenbezogenen Daten zu verlangen. Die M&ouml;glichkeit einer tats&auml;chlichen L&ouml;schung richtet sich dabei nach den unter vorgenannter Ziff. 3 genannten Vorgaben. Die L&ouml;schung Ihrer Daten erfolgt gem. den oben unter 3. beschriebenen Regeln.</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul>
            <li><span data-contrast="auto"> Einschr&auml;nkung der Verarbeitung, Art. 13 Abs. 2 lit. b), Art. 18 DSGVO</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">Sie haben das Recht von uns die Einschr&auml;nkung der Verarbeitung zu verlangen. Dies ist insbesondere dann relevant, wenn einer L&ouml;schung Gr&uuml;nde entgegenstehen. Ihre personenbezogenen Daten werden ab diesem Zeitpunkt nur noch mit Ihrer Einwilligung verarbeitet.</span><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul>
            <li><span data-contrast="auto"> Recht auf Daten&uuml;bertragbarkeit, Art. 13 Abs. 2 lit. b), Art. 20 DSGVO Sie haben das Recht von uns eine &Uuml;bertragung Ihrer personenbezogenen Daten an sich selbst oder Dritte in einem strukturierten, g&auml;ngigen und maschinenlesbaren Format zu verlangen.</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul>
            <li><span data-contrast="auto"> Widerruf der Einwilligung, Art. 13 Abs. 2 lit. c) DSGVO Sie haben das Recht die uns gegen&uuml;ber nachfolgend zu erteilende Einwilligung jederzeit zu widerrufen. Dies ber&uuml;hrt jedoch nicht die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung.</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <ul>
            <li><span data-contrast="auto"> Beschwerderecht, Art. 13 Abs. 2 lit. d) DSGVO Sie haben das Recht, jederzeit eine Beschwerde an eine Datenschutzaufsichtsbeh&ouml;rde zu richten. Die f&uuml;r uns zust&auml;ndige Datenschutzaufsichtsbeh&ouml;rde ist:</span></li>
          </ul>
          <p><span data-ccp-props="{}">&nbsp;</span></p>
          <p><span data-contrast="auto">LfD Niedersachsen Prinzenstr.5 30159 Hannover</span></p>

        </v-card-text>
      </v-card>

      <div class="d-flex justify-center mt-3 mt-sm-4">
        <a href="/static-downloads/Kundeninformation.pdf" download class="text-decoration-none" style2="width: 100%">
          <v-btn color="primary" class="" outlined2 block>Als PDF herunterladen</v-btn>
        </a>
      </div>

    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "CustomerInformation"
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}
</style>